<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<BannerHighlightForm
		v-else
		is-edit-mode
		:is-loading="isLoading"
		:is-updating="isUpdating"
		:data="data"
		:banner-type="bannerType"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import BannerHighlightForm from '@/components/BannerHighlightForm.vue';

import { BANNER_TYPE } from '../enums/banners';
import { HOMEPAGE_COMPONENTS } from '../enums/homepage';
import { scrollToTop } from '../assets/js/helpers';

export default {
	name: 'BannerHighlightEdit',

	components: {
		BannerHighlightForm,
	},

	props: {
		bannerType: {
			type: String,
			default: BANNER_TYPE.highlight,
		},
	},

	data() {
		return {
			id: Number(this.$route.params.id) || null,
		};
	},

	computed: {
		...mapState('banners', {
			edit: 'edit',
		}),

		...mapGetters({
			getBannerGroupDetail: 'banners/getBannerGroupDetail',
		}),

		isLoading() {
			return this.edit.isLoading;
		},

		isUpdating() {
			return this.edit.isUpdating;
		},

		data() {
			return this.getBannerGroupDetail;
		},
	},

	watch: {
		data(value) {
			this.setPageTitle({
				title: value.name,
				isShow: true,
			});
		},
	},

	async created() {
		await this.getBannerGroup({ id: this.id, type: this.bannerType });

		this.setPageTitle({
			title: this.data.name,
			isShow: true,
		});

		this.addBreadcrumb({
			title: HOMEPAGE_COMPONENTS.BANNER_HIGHLIGHT.title,
			route: {
				name: 'BannerHighlightList',
			},
		});
	},

	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
			addBreadcrumb: 'breadcrumb/addBreadcrumb',
			showToast: 'toast/showToast',

			getBannerGroup: 'banners/getBannerGroup',
			updateBannerGroup: 'banners/updateBannerGroup',
		}),

		async handleSubmit(updatedData) {
			try {
				await this.updateBannerGroup({ id: this.id, params: updatedData });
			} finally {
				scrollToTop();
			}
		},
	},
};
</script>
